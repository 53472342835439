import { render, staticRenderFns } from "./layout-market-place-1.vue?vue&type=template&id=0ea7d132&scoped=true&lang=html"
import script from "./layout-market-place-1.vue?vue&type=script&lang=js"
export * from "./layout-market-place-1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea7d132",
  null
  
)

export default component.exports