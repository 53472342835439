import { render, staticRenderFns } from "./FooterDefault.vue?vue&type=template&id=1b780fe1&scoped=true&lang=html"
import script from "./FooterDefault.vue?vue&type=script&lang=js"
export * from "./FooterDefault.vue?vue&type=script&lang=js"
import style0 from "./FooterDefault.vue?vue&type=style&index=0&id=1b780fe1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b780fe1",
  null
  
)

export default component.exports