import { render, staticRenderFns } from "./layout-default-ssr.vue?vue&type=template&id=665c5495&scoped=true&lang=html"
import script from "./layout-default-ssr.vue?vue&type=script&lang=js"
export * from "./layout-default-ssr.vue?vue&type=script&lang=js"
import style0 from "./layout-default-ssr.vue?vue&type=style&index=0&id=665c5495&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665c5495",
  null
  
)

export default component.exports