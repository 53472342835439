import { render, staticRenderFns } from "./HeaderMarketPlace2.vue?vue&type=template&id=fc4b0f92&scoped=true&lang=html"
import script from "./HeaderMarketPlace2.vue?vue&type=script&lang=js"
export * from "./HeaderMarketPlace2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc4b0f92",
  null
  
)

export default component.exports