<template>
    <div class="ps-footer__widgets">
        <aside class="widget widget_footer widget_contact-us">
            <h4 class="widget-title-new">
                Navigation
            </h4>
            <ul class="link-name">
                <li>
                    <a href="/about-us">About Us</a>
                </li>


                <li>

                    <a href="/contact-us">Contact Us</a>


                </li>
                <li>
                    <a href="/our-stores">Our Store</a>
                </li>
                <li>
                    <a href="/latest-mobile-blogs">Blogs</a>
                </li>
                <li>
                    <a href="/sitemap">Sitemap</a>
                </li>
            </ul>
        </aside>
        <aside class="widget widget_footer">
            <h4 class="widget-title-new">
                Policy
            </h4>
            <ul class="link-name">
                <li>
                    <a href="/privacy_policy">Privacy Policy</a>
                </li>


                <!-- <li>
                      <a href="/return_refund_policy">Return & Refund Policy</a>
                </li> -->
                <li>
                    <a href="/cancellation-policy">Cancellation-policy</a>
                </li>
                <li>
                    <a href="/terms-and-conditions">Terms & Conditions</a>
                </li>
            </ul>
        </aside>
        <aside class="widget widget_footer">
            <h4 class="widget-title-new">
                Other Pages
            </h4>
            <ul class="link-name">
                <li>
                    <a href="/faqs">FAQs</a>
                </li>
                <li>
                    <a href="/blood-donation"> Blood Donations</a>
                </li>
                <li>
                    <a href="/account/user-information">My account</a>
                </li>
                <li>
                    <a href="/assured_by_back">Assured by Back</a>
                </li>
                <li>
                    <a href="/akshaya-patra">Akshaya Patra</a>
                </li>
                <li>
                    <a href="https://careers.happimobiles.com">Careers</a>
                </li>
            </ul>
        </aside>
        <!-- <aside class="widget widget_footer widget_contact-us">
            <h4 class="widget-title">
            Navigation
            </h4>
            <ul class="ps-list--link">
                  <li>
                       <a href="/about-us">About Us</a>
                </li>


                 <li>

                      <a href="/contact-us">Contact Us</a>


                </li>
                  <li>
                    <a href="/our-stores">Our Store</a>
                </li>
                <li>
                    <a href="/latest-mobile-blogs">Blogs</a>
                </li>
                <li>
                    <a href="/">Sitemap</a>
                </li>
            </ul>
        </aside> -->

        <aside class="widget widget_footer">
            <h4 class="widget-title-new">
                Categories
            </h4>
            <ul class="link-name">
                <li>
                    <a href="/mobiles/all">Smart Phones</a>
                </li>
                <li>
                    <a href="/accessories/all">Accessories</a>
                </li>
                <li>
                    <a href="/laptops/all">Laptops</a>
                </li>
                <li>
                    <a href="/tvs/all">TV's</a>
                </li>
                <li>
                    <a href="/tablets/all">Tablets</a>
                </li>
            </ul>
        </aside>
        <aside class="widget widget_footer">
            <h4 class="widget-title-new">
                Best Deals
            </h4>
            <ul class="link-name">
                <li>
                    <a href="/">Super Deals</a>
                </li>
                <li>
                    <a href="/">Best Selling Products</a>
                </li>
                <li>
                    <a href="/">Exclusive offers</a>
                </li>
            </ul>
        </aside>
        <aside class="widget widget_footer">
            <h4 class="widget-title-new">
                Avaliable Now on
            </h4>
            <ul class="link-name">
                <li class="app-logo p-2">
                    <a href="https://play.google.com/store/apps/details?id=com.happimobiles" target="_blank"><img
                            class="footer-image"
                            v-lazy-load data-src="https://s3.ap-south-1.amazonaws.com/happimobiles/retool-upload/386d1fab-4876-42b0-a304-c92a5035941c.png"
                            alt="happi safe payment" /> </a> &nbsp;
                </li>
                <li class="app-logo p-2">
                    <a href="https://apps.apple.com/in/app/happi-mobiles/id1520143042" target="_blank"> <img
                            class="footer-image"
                            v-lazy-load data-src="https://s3.ap-south-1.amazonaws.com/happimobiles/retool-upload/521af805-e257-471e-b795-baada117fe1e.png"
                            alt="happi safe payment" /> </a> &nbsp;
                </li>
            </ul>
        </aside>
        <!-- <aside class="widget widget_footer">
            <h4 class="widget-title">
               About
            </h4>
            <ul class="ps-list--link">
                <li>
                    <a href="/about-us">About Us</a>
                </li>
                <li>
                    <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                    <a href="/our-stores">Our Store</a>
                </li>
                 <li>
                    <a href="/latest-mobile-blogs">Blogs</a>
                </li>
                 <li>
                    <a href="/terms_and_conditions">Terms & Conditions</a>
                </li>

            </ul>
        </aside> -->
        <!-- <aside class="widget widget_footer">
            <h4 class="widget-title">
               Other Pages
            </h4>
            <ul class="ps-list--link">
                <li>
                    <a href="/faqs">FAQs</a>
                </li>
                <li>
                    <a href="/blood-donation"> Blood Donations</a>
                </li>
                <li>
                    <a href="/account/user-information">My account</a>
                </li>
                <li>
                    <a href="/assured_by_back">Assured by Back</a>
                </li>
                <li>
                    <a href="/akshaya-patra">Akshaya Patra</a>
                </li>
            </ul>
        </aside> -->
    </div>
</template>
<script>
export default {
    name: 'FooterWidgetsNew'
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.app-logo {
    width: 200px;
    height: 44px;
}

.widget-title-new {
    // width: 193.33px;
    // height: 25px;

    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px;
    // line-height: 25px;
    /* identical to box height */


    color: #000000;
}

.link-name li a {
    // width: 193.33px;
    // height: 150px;

    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 30px !important;
    /* or 200% */


    color: rgba(0, 0, 0, 0.5);
}</style>
