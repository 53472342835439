<template lang="html">
    <div class="ps-loading--progress">
        <i>Loading...</i>
        <v-progress-linear indeterminate color="yellow darken-2" />
    </div>
</template>

<script>
export default {
    name: 'Loading'
};
</script>

<style lang="scss" scoped></style>
