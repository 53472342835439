const middleware = {}

middleware['authentication'] = require('../middleware/authentication.js')
middleware['authentication'] = middleware['authentication'].default || middleware['authentication']

middleware['nonAuthentication'] = require('../middleware/nonAuthentication.js')
middleware['nonAuthentication'] = middleware['nonAuthentication'].default || middleware['nonAuthentication']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['test'] = require('../middleware/test.js')
middleware['test'] = middleware['test'].default || middleware['test']

export default middleware
